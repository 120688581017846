<template>
    <div>
        <div
            class="mt-6 mb-3"
        >
            <ListActionsButtonsAdd
                ref="add"
                class="mr-3"
                :setup-dialog="true"
                :permission-name="permissionName"
                :request-name="requestName"
                :translation-name="translationName"
                :default-url="defaultUrl"
                @is-open="$event ? getCommunes() : createData = {}"
            >
                <template #selection>
                    <v-autocomplete
                        v-model="createData['commune_code']"
                        :items="communesData"
                        label="Choisissez la commune de votre PEI"
                        variant="outlined"
                        density="compact"
                        item-title="nom"
                        item-value="k"
                        no-data-text="Aucun choix disponible"
                    />
                    <v-switch
                        v-model="createData['en_projet']"
                        hide-details
                        color="success"
                        label="PEI en projet"
                    />
                </template>
                <template #validation>
                    <v-btn
                        v-if="!createData.commune_code"
                        color="success"
                        disabled
                    >
                        Valider
                    </v-btn>
                    <NuxtLink
                        v-else
                        :to="getCreateRequest()"
                    >
                        <v-btn
                            color="success"
                            @click="$refs.add.closeModal()"
                        >
                            Valider
                        </v-btn>
                    </NuxtLink>
                </template>
            </ListActionsButtonsAdd>

            <ListActionsButtonsExport
                :query="$refs?.filters?.query"
                :request-name="requestName"
                :translation-name="translationName"
                :exportable="exportable"
            />
        </div>

        <ListFilters
            v-if="filters"
            ref="filters"
            :filters-overload="filtersOverload"
            :more-filters-overload="moreFiltersOverload"
            :permission-name="permissionName"
            :filters="filters"
            :loading="loading"
            :translation-name="translationName"
            @load-data="loadData()"
            @reset-pagination="$refs.pagination?.resetPagination()"
        />

        <v-tabs
            v-model="tabs"
            :grow="true"
            color="secondary"
            selected-class="tab-active"
        >
            <!-- grow -->
            <v-tab value="liste">
                <v-icon>mdi-format-list-checkbox</v-icon>
                <span
                    v-if="!$vuetify.display.mobile"
                    class="ml-3"
                >Liste</span>
            </v-tab>
            <v-tab value="carte">
                <!--  @click="loadMapData()" -->
                <v-icon>mdi-map-legend</v-icon>
                <span
                    v-if="!$vuetify.display.mobile"
                    class="ml-3"
                >Carte</span>
            </v-tab>
        </v-tabs>
        <v-card>
            <v-window
                v-model="tabs"
                disabled
            >
                <v-window-item
                    value="liste"
                    class="pa-5"
                >
                    <div class="mb-2">
                        <v-row no-gutters>
                            <v-col
                                cols="12"
                                md="3"
                                class="mt-3"
                            >
                                <!-- && $permissionCheck('pei_edit_row') -->
                                <ListEditRow
                                    v-if="$refs.table"
                                    ref="editRow"
                                    :disabled="$refs.table && !$refs.table.selectedData.length"
                                    :selected="$refs.table.selectedData"
                                    :translation-name="translationName"
                                    :request-name="requestName"
                                    :permission-name="permissionName"
                                    @clean-selected="$refs.table.selectedData = []"
                                    @load-data="loadData()"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                md="5"
                            />
                            <v-col
                                cols="12"
                                md="4"
                                class="pa-3"
                            >
                                <!-- label="Affichage des colonnes" -->
                                <v-select
                                    v-if="enabledTemplate.length > 1"
                                    v-model="template"
                                    density="compact"
                                    variant="solo"
                                    :items="enabledTemplate"
                                    hide-details="true"
                                >
                                    <template #selection="{ item }">
                                        {{ $translate(`${translationName}.template.${item.value}`) }}
                                    </template>
                                    <template #item="{ props, item }">
                                        <v-list-item v-bind="props">
                                            <v-list-item-title class="select-options">
                                                {{ $translate(`${translationName}.template.${item.value}`) }}
                                            </v-list-item-title>
                                        </v-list-item>
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>
                    </div>
                    <ListTable
                        ref="table"
                        :dbl-click-columns="dblClickColumns"
                        :permission-name="permissionName"
                        :translation-name="translationName"
                        :sortable="sortable"
                        :default-url="defaultUrl"
                        :loading="loading"
                        :columns="columns[template]"
                        :data="data"
                        :is-selectable="$permissionCheck('pei_update_all')"
                        :init-sort="initSort"
                        :value-as-view-link="valueAsViewLink"
                        permission-to-check="pei_display_all"
                        @reset-pagination="$refs.pagination.resetPagination()"
                        @load-data="loadData()"
                        @delete-data="deleteData($event)"
                        @restore-data="restoreData($event)"
                        @dbl-click-column="editRows($event)"
                    >
                        <template #optionsList="{ item }">
                            <!-- PDF -->
                            <v-list-item
                                v-if="$permissionCheck(`${permissionName}_show`) && !item.deleted"
                                density="compact"
                                class="link-as-list-item"
                            >
                                <DocumentsDownloadLink
                                    :filename="`${item.k}.pdf`"
                                    :url="`${requestName}/${item.k}?format=pdf`"
                                >
                                    <v-list-item-title class="text-caption">
                                        <v-icon
                                            icon="mdi-file-pdf-box"
                                            size="small"
                                            class="mr-2"
                                            color="dark"
                                        />
                                        {{ $translate(`${translationName}.pdf`) }}
                                    </v-list-item-title>
                                </DocumentsDownloadLink>
                            </v-list-item>
                            <!-- ASK ACTIVE -->
                            <NuxtLink
                                :to="`${defaultUrl}/${item.k}/formulaire?en_projet=false`"
                                class="link-as-list-item"
                            >
                                <v-list-item
                                    v-if="$permissionCheck(`${permissionName}_project_edit`) && item.en_projet && (item.meta?.editable || !item.meta)"
                                    density="compact"
                                    class="link-as-list-item"
                                >
                                    <v-list-item-title class="text-caption text-warning">
                                        <v-icon
                                            icon="mdi-timer-sand"
                                            size="small"
                                            class="mr-2"
                                            color="warning"
                                        />
                                        {{ $translate(`${translationName}.project_active`) }}
                                    </v-list-item-title>
                                </v-list-item>
                            </NuxtLink>
                            <!-- ASK DELETE -->
                            <v-list-item
                                v-if="$permissionCheck(`peiDeletionDemand_store`) && !item.deleted && (item.meta?.editable || !item.meta)"
                                density="compact"
                                class="link-as-list-item"
                            >
                                <v-list-item-title class="text-caption">
                                    <ListDeleteConfirmation
                                        action-name="askDelete"
                                        :with-textarea="true"
                                        :item="item"
                                        :translation-name="translationName"
                                        :button="`<v-list-item-title class='text-caption text-danger' ><v-icon icon='mdi-email-remove-outline' size='small' class='mr-3' color='danger'></v-icon>${$translate(translationName + '.askDelete')}</v-list-item-title>`"
                                        @delete-data="askDelete($event)"
                                    />
                                </v-list-item-title>
                            </v-list-item>
                        </template>
                    </ListTable>
                    <div>
                        <ListPagination
                            ref="pagination"
                            :loading="loading"
                            :last-page="lastPage"
                            :total="total"
                            @load-data="loadData"
                        />
                    </div>
                </v-window-item>
                <v-window-item value="carte">
                    <Map
                        ref="map"
                        :height="650"
                        :loading="loadingMap"
                        :min-zoom="6"
                        :max-zoom="18"
                        :custom-legend="[{ code : requestName, query : $refs.filters?.getQuery() || null, translationCode : `${translationName}.type_hydrant` }]"
                        :custom-legend-icons-path="`${$config.public.apiBase}/map-symbols`"
                        @delete-layer="$refs.map.deleteLayer($event);"
                    >

                        <map-layer-pixi-overlay-markers
                            v-if="geojson"
                            :geojson="geojson"
                            layer-name="peis"
                            :icons-path="`${$config.public.apiBase}/map-symbols`"
                            :zoom-on-layer="true"
                            @loading-map="loadingMap = $event"
                            @mouse-click="clickPei"
                        />

                        <map-layer-cover-distances
                            v-if="$permissionCheck('pei_covers')"
                            :query="$refs?.filters?.query"
                            :loading-map="loadingMap"
                        />

                        <MapControlsGeosearch />
                        <MapControlsScale />
                        <MapControlsZoombox />
                        <MapControlsLocate />
                        <MapControlsMeasure />
                    </Map>
                </v-window-item>
            </v-window>
        </v-card>
        <NuxtPage
            :request-name="requestName"
            :default-url="defaultUrl"
            :translation-name="translationName"
            :permission-name="permissionName"
            @load-data="loadData()"
            @delete-data="deleteData($event)"
        />
    </div>
</template>

<script lang="ts">
import tablePageInit from '~/packages/datakode/nuxt-datakode-utils/utils/tablePageInit.vue'

import {ApiService} from "~/packages/datakode/nuxt-datakode-utils/utils/api.service";
import type L from "leaflet";
import type {Router} from "vue-router";

import {useConfigStore} from '~/store'
// import type PixiOverlayMarkers from "~/components/map/layer/PixiOverlayMarkers.client.vue";

export default {
    name: "ListePEIs",
    // components: {
    //     PixiOverlayMarkers
    // },
    extends: tablePageInit,
    setup() {
        useHead({
            title: "Hydraclic | Point d'eau incendie",
            meta: [
                {name: 'description', content: "Gestion des points d'eau incendie"}
            ]
        })
        definePageMeta({
            alias: "/gestion/carte"
            // middleware: ['auth', 'is-admin']
        });
        const configStore = useConfigStore()
        return {
            configStore,
        }
    },
    data() {
        return {
            show_map_pei_page : this.configStore.currentConfig.show_map_pei_page,

            hasMap: true,
            covers: null,
            communesData: [],
            tabs: this.show_map_pei_page ? 'liste' : useState('peiTab', () => 'liste'),
            dblClickColumns: ['address_complement', 'last_technical_control.debit_1bar', 'last_technical_control.debit_max', 'last_technical_control.pression_statique', 'last_technical_control.volume_pa', 'last_technical_control.date_control', 'last_technical_control.controller_name',
                'last_operational_recognition.controller_name', 'last_operational_recognition.date_control', 'status', 'street_address', 'type_hydrant'
            ],
            permissionName: 'pei',
            requestName: 'peis',
            defaultUrl: '/gestion/pei',
            translationName: 'gestion.pei',
            template: 'basic',
            filtersOverload: ['numero_long', 'street_address', 'commune_code', 'zone_tour.id', 'type_hydrant', 'etat_start', 'status', 'operational_recognitions.centre_secours_territory_id', 'where_en_projet', 'has_trashed', 'where_has_active_deletion_demands', 'where_has_late_ct', 'where_has_late_ro', 'where_has_anomaly','display_all_territories', 'territory_id'
            ],
            moreFiltersOverload: ['last_technical_control.date_control', 'last_technical_control.debit_1bar', 'last_technical_control.debit_max', 'last_technical_control.pression', 'last_technical_control.pression_statique', 'last_technical_control.volume_pa', 'last_operational_recognition.date_control'],
            valueAsViewLink: ['numero_long'],
            columns: {
                basic: [
                    {dataKey: 'commune.nom'},
                    {dataKey: 'numero_long', template: [this.setNameTemplate, this.showDisabledPei]},
                    {dataKey: 'etat_start', transform: [this.toTranslate], template: [this.isChips, this.checkProject]},
                    {dataKey: 'type_hydrant', transform: [this.toTranslate], template: [this.isChips]},
                    {dataKey: 'street_address'},
                    {dataKey: 'status', transform: [this.toTranslate], template: [this.isChips]},
                    {dataKey: 'last_technical_control.pression'},
                    {dataKey: 'last_technical_control.pression_statique'},
                    {dataKey: 'last_technical_control.debit_1bar'},
                    {dataKey: 'last_technical_control.debit_max'},
                    {dataKey: 'last_technical_control.volume_pa'},
                    {dataKey: 'anomalies', transform: [this.showAnomaliesLength]},
                ],
                ct: [
                    {dataKey: 'commune.nom'},
                    {dataKey: 'numero_long', template: [this.setNameTemplate]},
                    {dataKey: 'etat_start', transform: [this.toTranslate], template: [this.isChips]},
                    {dataKey: 'type_hydrant', transform: [this.toTranslate], template: [this.isChips]},
                    {dataKey: 'street_address'},
                    {dataKey: 'status', transform: [this.toTranslate], template: [this.isChips]},
                    {
                        dataKey: 'last_technical_control.date_control',
                        transform: [this.isDate],
                        template: [this.checkCTAlert]
                    },
                    {dataKey: 'last_technical_control.controller_name'},
                ],
                ro: [
                    {dataKey: 'commune.nom'},
                    {dataKey: 'numero_long', template: [this.setNameTemplate]},
                    {dataKey: 'etat_start', transform: [this.toTranslate], template: [this.isChips]},
                    {dataKey: 'type_hydrant', transform: [this.toTranslate], template: [this.isChips]},
                    {dataKey: 'street_address'},
                    {dataKey: 'status', transform: [this.toTranslate], template: [this.isChips]},
                    {dataKey: 'last_operational_recognition.centre_mixte_territory.name', template: [this.isChips]},
                    {dataKey: 'last_operational_recognition.centre_secours_territory.name', template: [this.isChips]},
                    {
                        dataKey: 'last_operational_recognition.date_control',
                        transform: [this.isDate],
                        template: [this.checkROAlert]
                    },
                    {dataKey: 'last_operational_recognition.controller_name'},
                ],
            },
            initSort: {
                //     key : "numero_long",
                //     order : "asc"
            },
        }
    },

    computed: {
        enabledTemplate() {
            let columns = Object.keys(this.columns)
            if (!this.$permissionCheck(`technicalControl_show`)) {
                columns = columns.filter(col => col != 'ct')
            }
            if (!this.$permissionCheck(`operationalRecognition_show`)) {
                columns = columns.filter(col => col != 'ro')
            }
            return columns
        },
        routeParams() {
            return this.$route.query
        }
    },

    watch: {
        routeParams () {
            if (this.routeParams['onglet']) {
                this.tabs = this.routeParams['onglet']
            }
        }
    },
    mounted() {
        if (this.routeParams['onglet']) {
            this.tabs = this.routeParams['onglet'];
        }
    },
    methods: {
        async askDelete(item) {
            if (item.k) {
                this.$api.setRequest({
                    url: `${this.requestName}/${item.k}/deletion-demands`,
                    method: "POST",
                    translation: `${this.translationName}.askDelete`,
                    body: item
                })
                this.loadData()
            }
        },
        getCommunes() {
            this.$api.getData({
                url: `/communes?page[size]=500`
            }).then(res => {
                this.communesData = res.data
            })
        },
        checkCTAlert(key, value, template, item) {
            let html = template
            const alertCheck = item.alerts?.find(alert => alert.alert_key === "PeiLastTechnicalControlDateAlert")
            if (alertCheck) {
                html = `
                        <v-tooltip text="${alertCheck.name}">
                            <template #activator="{ props }">
                                <v-chip color="${alertCheck.level_name}" size="small" v-bind="props">
                                ${template}
                            </v-chip>
                            </template>
                        </v-tooltip>
                        `
            }
            return html
        },
        checkROAlert(key, value, template, item) {
            let html = template
            const alertCheck = item.alerts?.find(alert => alert.alert_key === "PeiLastOperationalRecognitionDateAlert")
            if (alertCheck) {
                html = `
                        <v-tooltip text="${alertCheck.name}">
                            <template #activator="{ props }">
                                <v-chip color="${alertCheck.level_name}" size="small" v-bind="props">
                                ${template}
                            </v-chip>
                            </template>
                        </v-tooltip>
                        `
            }
            return html
        },
        checkProject(key, value, template, item) {
            if (item.en_projet) {
                return `
                        <v-chip color="primary" size="small">
                            <v-icon start icon="mdi-timer-sand" size="small"></v-icon>
                            En projet
                        </v-chip>
                        `
            }
            return template
        },
        setNameTemplate(key, value, template, item) {
            let html = value
            if (item.zone_tour) {
                html += "<br/>"
                html += `
                        <v-chip color="grey" size="x-small" class="mb-1">
                            ${item.zone_tour.number}
                        </v-chip>
                        `
            }
            if (item.active_deletion_demands_count > 0 && this.$permissionCheck('peiDeletionDemand_index') && (item.meta?.editable || !item.meta)) {
                html += "<br/>"
                html += `
                        <v-chip color="error" size="x-small">
                            <v-icon start icon="mdi-alert" size="small"></v-icon>
                            ${item.active_deletion_demands_count} demande${item.active_deletion_demands_count > 1 ? 's' : ''} de suppression
                        </v-chip>
                        `
            }

            return html
        },
        showAnomaliesLength(key, value) {
            let html = ""
            const anomaliesType = Object.keys(value) // ['er', 'b']

            anomaliesType.forEach(type => {
                html += `<v-tooltip text="${this.$translate(`${this.translationName}.${key}.${type}`)} : ${value[type].name}">
                            <template v-slot:activator="{ props }">
                                <v-chip class="mr-2 mb-1" v-bind="props" color="${this.$getColor(`${this.translationName}.${key}.${type}`)}">
                                    ${value[type].count}
                                </v-chip>
                            </template>
                        </v-tooltip>`
            })
            return html
        },
        showDisabledPei(key, value, template, item) {
            let html = template
            if (item.deleted) {
                html += ` <br/>
                        <v-chip color="error" size="x-small">
                            Archivé
                        </v-chip>
                    `
            }
            return html
        },
        editRows(column) {
            this.$refs.editRow.shown = true
            this.$refs.editRow.singleEdit = column
        },
        clickPei(latLng: L.LatLng, zoom: number) {
            const api = this.$api as ApiService;
            const distance =
        Math.pow(2, 18) / Math.pow(2, zoom)
        * 10 // estimated marker size at zoom 18;

            const query = this.$refs.filters?.getQuery()

            api.getListData({
                url: 'peis',
                query : {
                    ...query,
                    where_distance_is_less_than: latLng.lat + ',' + latLng.lng + ',' + distance
                },
                pagination : {
                    size: 1,
                    number : 1,
                },
                sort : { key : 'distance_is_less_than', order: 'asc' }
            }).then(data => {
                if (data.data.length > 0) { //Pei found
                    const peiData = data.data[0];

                    const map = this.$refs.map.$data.map as L.Map;
                    const html = `<div style="cursor:pointer;font-size:14px;">
                    Numéro long : ${peiData.numero_long} <br>
                    Adresse : ${peiData.street_address ? peiData.street_address : ''} ${peiData.address_complement ? peiData.address_complement : ''} <br>
                    Ville :  ${peiData.commune?.nom} <br>
                    Statut : ${peiData.status} <br>
                    Type hydrant : ${peiData.type_hydrant} <br>
                    <i style="font-size:12px;">Cliquez sur la bulle pour ouvrir le détail du PEI</i></div>`;

                    const popup = L.popup().setContent(html).setLatLng(latLng).openOn(map)
                    L.DomEvent.on(popup._contentNode, 'click', () => {
                        (this.$router as Router).push(`/gestion/pei/${peiData.numero_long}`)
                    });
                }

            })
        },

        // overPei(latLng: LatLng, zoom: number) {
        // },

    },
};
</script>
